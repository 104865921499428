<!-- @format -->
<script lang="ts">
  import type { StoresKey } from "~/stores";
  import { getFragmentConfigOr } from "~/components/field-utils";

  export let request_store_key: StoresKey;
  export let fragment: Fragment;
  import Media from "./Media.svelte";
  import AnimatedTexts from "./AnimatedTexts.svelte";
  import Pulse from "./Pulse.svelte";
  const fill = getFragmentConfigOr("pulseColor", fragment, "#D0A4B1");
</script>

<div class="main-banner">
  <div class="animated-texts top">
    <AnimatedTexts {request_store_key} {fragment} position="top-right" />
  </div>
  <div class="ticker" id="main-banner">
    <div class="loop1">
      <div class="animate">
        <Pulse pulseColor={fill} />
        {#each ["1", "2", "3", "4", "6"] as item}
          <Media type={item} {fragment} {request_store_key} />
        {/each}
      </div>
    </div>
    <div class="loop2">
      <div class="animate">
        <Pulse pulseColor={fill} />
        {#each ["1", "2", "3", "4", "6"] as item}
          <Media type={item} {fragment} {request_store_key} />
        {/each}
      </div>
    </div>
  </div>
  <div class="animated-texts bottom">
    <AnimatedTexts {request_store_key} {fragment} position="bottom-left" />
    <AnimatedTexts {request_store_key} {fragment} position="bottom-right" />
  </div>
</div>

<style lang="scss">
  :root {
    --banner-width: 1570.93px;
    --banner-height: 211.38px;
  }

  @media (min-width: 992px) {
    :root {
      --banner-width: 2378.18px;
      --banner-height: 320px;
    }
  }

  @media (min-width: 1600px) {
    :root {
      --banner-width: 3614px;
      --banner-height: 487px;
    }
  }
  .main-banner {
    overflow: hidden;
  }
  .animate {
    animation: shape-small 1s ease-in;
    height: var(--banner-height);
    width: 100%;
    position: relative;
    @media screen and (min-width: 992px) {
      animation: shape-medium 3s ease-in;
    }

    @media screen and (min-width: 1600px) {
      animation: shape-large 3s ease-in;
    }
  }

  @keyframes shape-small {
    from {
      clip-path: path(
        "M631.346 105.5C582.128 105.5 578.783 105.5 526.347 105.5C496.78 105.5 486.274 105.5 486.274 105.5C486.274 105.5 475.761 105.5 446.193 105.5C401.259 105.5 375.816 105.5 349.824 105.5C326.56 105.5 320.272 105.5 288.243 105.5C257.06 105.5 255.826 105.5 235.713 105.5C210.8 105.5 185.565 105.5 130.395 105.5C69.2987 105.5 61.7095 105.5 0 105.5V106C61.7095 106 69.2919 106 130.395 106C185.572 106 210.8 106 235.713 106C255.826 106 257.067 106 288.243 106C320.279 106 326.56 106 349.824 106C375.816 106 401.265 106 446.193 106C478.16 106 486.274 106 486.274 106C486.274 106 494.387 106 526.347 106C578.783 106 582.128 106 631.346 106C693.055 106 700.645 106 761.74 106C814.511 106 827.75 106 864.659 106C887.172 106 888.5 105.5 918.826 106C946 105.5 949.085 106 976.788 106C1013.83 106 1025.41 106 1067.94 106C1093.71 106 1105.11 106 1122.5 106C1139.9 106 1151.3 201.766 1176.89 201.766C1229.32 201.766 1223.03 127.73 1272.24 127.73C1299.56 127.73 1328.86 158.015 1343.25 187.098C1357.65 158.015 1380.71 127.73 1408.41 127.73C1436.12 127.73 1459.18 158.015 1473.57 187.098C1487.97 158.015 1522.02 110.136 1570.93 110.136V101.542C1522.02 101.542 1487.97 53.0691 1473.57 23.9859C1459.18 53.0691 1436.12 83.3541 1408.41 83.3541C1380.71 83.3541 1357.65 53.0691 1343.25 23.9859C1328.86 53.0691 1299.56 83.3541 1272.24 83.3541C1223.03 83.3541 1229.32 9.31836 1176.89 9.31836C1151.12 9.31836 1139.72 35.0795 1122.33 35.0795C1104.93 35.0795 1093.53 105.5 1067.94 105.5C1025.41 105.5 1013.83 105.5 976.788 105.5C949.085 105.5 948 105.5 918.826 105.5C887 105.5 887.172 105.5 864.659 105.5C827.75 105.5 814.511 105.5 761.74 105.5C700.645 105.5 693.055 105.5 631.346 105.5Z"
      );
    }

    to {
      clip-path: path(
        "M631.346 97.0678C582.128 97.0678 578.783 9.61858 526.347 9.61858C496.78 9.61858 486.274 35.3408 486.274 35.3408C486.274 35.3408 475.761 9.61858 446.193 9.61858C401.259 9.61858 375.816 97.0678 349.824 97.0678C326.56 97.0678 320.272 60.2655 288.243 60.2655C257.06 60.2655 255.826 91.6569 235.713 91.6569C210.8 91.6569 185.565 0.299828 130.395 0.299828C69.2987 0.299828 61.7095 101.542 0 101.542V110.137C61.7095 110.137 69.2919 211.379 130.395 211.379C185.572 211.379 210.8 120.022 235.713 120.022C255.826 120.022 257.067 151.413 288.243 151.413C320.279 151.413 326.56 114.611 349.824 114.611C375.816 114.611 401.265 202.06 446.193 202.06C478.16 202.06 486.274 176.338 486.274 176.338C486.274 176.338 494.387 202.06 526.347 202.06C578.783 202.06 582.128 114.611 631.346 114.611C693.055 114.611 700.645 211.085 761.74 211.085C814.511 211.085 827.75 153.309 864.659 153.309C887.172 153.309 904.429 172.407 918.826 199.092C933.222 172.407 949.085 150.82 976.788 150.82C1013.83 150.82 1025.41 201.766 1067.94 201.766C1093.71 201.766 1105.11 176.005 1122.5 176.005C1139.9 176.005 1151.3 201.766 1176.89 201.766C1229.32 201.766 1223.03 127.731 1272.24 127.731C1299.56 127.731 1328.86 158.016 1343.25 187.099C1357.65 158.016 1380.71 127.731 1408.41 127.731C1436.12 127.731 1459.18 158.016 1473.57 187.099C1487.97 158.016 1522.02 110.137 1570.93 110.137V101.542C1522.02 101.542 1487.97 53.0695 1473.57 23.9863C1459.18 53.0695 1436.12 83.3545 1408.41 83.3545C1380.71 83.3545 1357.65 53.0695 1343.25 23.9863C1328.86 53.0695 1299.56 83.3545 1272.24 83.3545C1223.03 83.3545 1229.32 9.31876 1176.89 9.31876C1151.12 9.31876 1139.72 35.0799 1122.33 35.0799C1104.93 35.0799 1093.53 9.31876 1067.94 9.31876C1025.41 9.31876 1013.83 60.2655 976.788 60.2655C949.085 60.2655 933.222 38.6777 918.826 11.9931C904.429 38.6777 887.172 57.7763 864.659 57.7763C827.75 57.7763 814.511 0 761.74 0C700.645 0 693.055 97.0678 631.346 97.0678Z"
      );
    }
  }


  @keyframes shape-medium {
    0% {
      clip-path: path(
        "M957.143 153.575C882.527 153.575 877.456 153.574 797.962 153.574C753.136 153.574 737.208 153.574 737.208 153.574C737.208 153.574 721.271 153.575 676.445 153.575C608.322 153.575 569.75 153.574 530.346 153.574C495.077 153.574 485.544 153.575 436.987 153.575C389.713 153.575 387.842 153.575 357.349 153.575C319.58 153.575 281.323 153.575 197.683 153.575C105.059 153.575 93.5538 153.574 0 153.574V166.603C93.5538 166.603 105.049 166.606 197.683 166.606C281.333 166.606 319.58 166.603 357.349 166.603C387.842 166.603 389.723 166.603 436.987 166.603C485.554 166.603 495.077 166.606 530.346 166.606C569.75 166.606 608.333 166.606 676.445 166.606C724.908 166.606 737.208 166.606 737.208 166.606C737.208 166.606 749.509 166.603 797.962 166.603C877.456 166.603 882.527 166.606 957.143 166.606C1050.7 166.606 1062.2 166.603 1154.83 166.603C1234.83 166.603 1254.9 166.606 1310.85 166.606C1344.98 166.606 1360.41 166.606 1393.7 166.606C1398.64 166.603 1438.85 166.603 1480.85 166.603C1537 166.603 1547.05 166.606 1611.53 166.606C1650.6 166.606 1675.11 166.603 1701.49 166.603C1727.86 166.603 1745.41 166.606 1784.21 166.606C1863.7 166.606 1854.15 166.606 1928.77 166.606C1970.17 166.606 1990.73 166.606 2036.42 166.606C2068.51 166.603 2100.99 166.606 2142.99 166.606C2184.99 166.606 2189.78 166.606 2233.99 166.606C2293.92 166.606 2307.44 166.603 2381.59 166.603V153.574C2307.44 153.574 2262.95 153.574 2233.99 153.574C2190.77 153.574 2184.99 153.574 2142.99 153.574C2100.99 153.574 2081.69 153.575 2036.42 153.575C1985.13 153.575 1970.17 153.575 1928.77 153.575C1854.15 153.575 1863.7 153.575 1784.21 153.575C1745.14 153.575 1727.86 153.575 1701.49 153.575C1675.11 153.575 1650.33 153.574 1611.53 153.574C1547.05 153.574 1537 153.574 1480.85 153.574C1438.85 153.574 1404.9 153.575 1393.7 153.575C1371.62 153.574 1344.98 153.575 1310.85 153.575C1254.9 153.575 1234.83 153.575 1154.83 153.575C1062.2 153.575 1050.7 153.575 957.143 153.575Z"       );
    }
    30% {
      clip-path: path(
        "M957.146 147.393C882.528 147.393 877.459 14.8143 797.963 14.8143C753.137 14.8143 737.206 53.8141 737.206 53.8141C737.206 53.8141 721.269 14.8143 676.442 14.8143C608.322 14.8143 569.75 147.395 530.346 147.395C495.077 147.395 485.544 91.6011 436.987 91.6011C389.713 91.6011 387.842 139.189 357.349 139.189C319.581 139.189 281.323 0.689453 197.683 0.689453C105.06 0.689453 93.554 154.177 0 154.177V167.208C93.554 167.208 105.049 320.689 197.683 320.689C281.333 320.689 319.581 182.19 357.349 182.19C387.842 182.19 389.723 229.784 436.987 229.784C485.554 229.784 495.077 173.99 530.346 173.99C569.75 173.99 608.333 306.565 676.442 306.565C724.907 306.565 737.206 267.571 737.206 267.571C737.206 267.571 749.512 306.565 797.963 306.565C877.459 306.565 882.528 173.989 957.146 173.989C1050.69 173.989 1062.2 173.99 1154.83 173.99C1234.83 173.99 1254.9 173.99 1310.85 173.99C1344.98 173.99 1342.29 173.99 1392.97 173.99C1434.56 173.99 1438.85 173.99 1480.84 173.99C1537 173.99 1554.55 173.99 1619.03 173.99C1658.11 173.99 1675.38 173.99 1701.75 173.99C1728.13 173.99 1745.41 173.99 1784.2 173.99C1863.7 173.99 1854.15 173.99 1928.77 173.99C1970.17 173.99 1996.13 173.99 2036.42 173.99C2065.99 173.99 2093.21 173.99 2135.21 173.99C2177.21 173.99 2191.55 173.99 2233.99 173.99C2261.75 173.99 2307.44 167.205 2381.58 167.205V154.176C2307.44 154.176 2268.34 147.395 2233.99 147.395C2199.64 147.395 2177.21 147.395 2135.21 147.395C2093.21 147.395 2073.57 147.395 2036.42 147.395C1993.16 147.395 1970.17 147.395 1928.77 147.395C1854.15 147.395 1863.7 147.395 1784.2 147.395C1745.14 147.395 1727.86 147.395 1701.48 147.395C1675.11 147.395 1657.84 147.395 1619.03 147.395C1554.55 147.395 1537 147.395 1480.84 147.395C1438.85 147.395 1435.55 147.395 1392.97 147.395C1357.78 147.395 1344.98 147.395 1310.85 147.395C1254.9 147.395 1234.83 147.395 1154.83 147.395C1062.2 147.395 1050.69 147.393 957.146 147.393Z"
      );
    }

    75% {
      clip-path: path(
        "M957.146 147.393C882.528 147.393 877.459 14.8143 797.963 14.8143C753.137 14.8143 737.206 53.8141 737.206 53.8141C737.206 53.8141 721.269 14.8143 676.442 14.8143C608.322 14.8143 569.75 147.395 530.346 147.395C495.077 147.395 485.544 91.6011 436.987 91.6011C389.713 91.6011 387.842 139.189 357.349 139.189C319.581 139.189 281.323 0.689453 197.683 0.689453C105.06 0.689453 93.554 154.177 0 154.177V167.208C93.554 167.208 105.049 320.689 197.683 320.689C281.333 320.689 319.581 182.19 357.349 182.19C387.842 182.19 389.723 229.784 436.987 229.784C485.554 229.784 495.077 173.99 530.346 173.99C569.75 173.99 608.333 306.565 676.442 306.565C724.907 306.565 737.206 267.571 737.206 267.571C737.206 267.571 749.512 306.565 797.963 306.565C877.459 306.565 882.528 173.989 957.146 173.989C1050.69 173.989 1062.2 173.99 1154.83 173.99C1234.83 173.99 1254.9 173.99 1310.85 173.99C1344.98 173.99 1342.29 173.99 1392.97 173.99C1434.56 173.99 1438.85 173.99 1480.84 173.99C1537 173.99 1554.55 173.99 1619.03 173.99C1658.11 173.99 1675.38 173.99 1701.75 173.99C1728.13 173.99 1745.41 173.99 1784.2 173.99C1863.7 173.99 1854.15 173.99 1928.77 173.99C1970.17 173.99 1996.13 173.99 2036.42 173.99C2065.99 173.99 2093.21 173.99 2135.21 173.99C2177.21 173.99 2191.55 173.99 2233.99 173.99C2261.75 173.99 2307.44 167.205 2381.58 167.205V154.176C2307.44 154.176 2268.34 147.395 2233.99 147.395C2199.64 147.395 2177.21 147.395 2135.21 147.395C2093.21 147.395 2073.57 147.395 2036.42 147.395C1993.16 147.395 1970.17 147.395 1928.77 147.395C1854.15 147.395 1863.7 147.395 1784.2 147.395C1745.14 147.395 1727.86 147.395 1701.48 147.395C1675.11 147.395 1657.84 147.395 1619.03 147.395C1554.55 147.395 1537 147.395 1480.84 147.395C1438.85 147.395 1435.55 147.395 1392.97 147.395C1357.78 147.395 1344.98 147.395 1310.85 147.395C1254.9 147.395 1234.83 147.395 1154.83 147.395C1062.2 147.395 1050.69 147.393 957.146 147.393Z"
      );
    }

    100% {
      clip-path: path(
        "M955.778 146.948C881.266 146.948 876.205 14.5613 796.823 14.5613C752.06 14.5613 736.152 53.5013 736.152 53.5013C736.152 53.5013 720.238 14.5613 675.476 14.5613C607.453 14.5613 568.936 146.948 529.588 146.948C494.37 146.948 484.85 91.2338 436.363 91.2338C389.156 91.2338 387.288 138.757 356.838 138.757C319.124 138.757 280.921 0.453901 197.4 0.453901C104.909 0.453901 93.4203 153.721 0 153.721V166.732C93.4203 166.732 104.899 320 197.4 320C280.931 320 319.124 181.697 356.838 181.697C387.288 181.697 389.166 229.22 436.363 229.22C484.86 229.22 494.37 173.506 529.588 173.506C568.936 173.506 607.463 305.893 675.476 305.893C723.871 305.893 736.152 266.953 736.152 266.953C736.152 266.953 748.44 305.893 796.823 305.893C876.205 305.893 881.266 173.506 955.778 173.506C1049.19 173.506 1060.68 319.555 1153.18 319.555C1233.07 319.555 1253.11 232.089 1308.98 232.089C1343.06 232.089 1369.19 261.002 1390.98 301.399C1412.77 261.002 1436.79 228.321 1478.73 228.321C1534.8 228.321 1552.33 305.448 1616.72 305.448C1655.74 305.448 1672.99 266.448 1699.32 266.448C1725.66 266.448 1742.91 305.448 1781.65 305.448C1861.03 305.448 1851.5 193.368 1926.01 193.368C1967.36 193.368 2011.72 239.215 2033.51 283.243C2055.3 239.215 2090.22 193.368 2132.16 193.368C2174.09 193.368 2209 239.215 2230.8 283.243C2252.59 239.215 2304.14 166.732 2378.18 166.732V153.721C2304.14 153.721 2252.59 80.3404 2230.8 36.3121C2209 80.3404 2174.09 126.187 2132.16 126.187C2090.22 126.187 2055.3 80.3404 2033.51 36.3121C2011.72 80.3404 1967.36 126.187 1926.01 126.187C1851.5 126.187 1861.03 14.1074 1781.65 14.1074C1742.64 14.1074 1725.39 53.1064 1699.05 53.1064C1672.72 53.1064 1655.47 14.1074 1616.72 14.1074C1552.33 14.1074 1534.8 91.2338 1478.73 91.2338C1436.79 91.2338 1412.77 58.553 1390.98 18.156C1369.19 58.553 1343.06 87.4658 1308.98 87.4658C1253.11 87.4658 1233.07 0 1153.18 0C1060.68 0 1049.19 146.948 955.778 146.948Z"
      );
    }
  }

  @keyframes shape-large {
    0% {
      clip-path: path(
        "M1452.17 223.267C1338.96 223.267 1331.27 223.27 1210.66 223.27C1142.65 223.27 1118.48 243 1118.48 243C1118.48 243 1094.3 243 1026.29 243C922.939 243 864.418 243 804.635 243C751.125 243 736.661 243 662.992 243C591.268 243 588.429 243 542.165 243C484.864 243 426.82 243 299.922 243C159.395 243 141.939 243 0 243V243.5C141.939 243.5 159.379 243.5 299.922 243.5C426.835 243.5 484.864 243.5 542.165 243.5C588.429 243.5 591.283 243.5 662.992 243.5C736.677 243.5 751.125 243.5 804.635 243.5C864.418 243.5 922.955 243.5 1026.29 243.5C1099.82 243.5 1118.48 243.5 1118.48 243.5C1118.48 243.5 1137.15 263.62 1210.66 263.62C1331.27 263.62 1338.96 263.618 1452.17 263.618C1594.1 263.618 1611.56 263.62 1752.09 263.62C1873.47 263.62 1903.92 263.62 1988.81 263.62C2040.59 263.62 2080.29 202.242 2113.4 263.62C2146.51 202.242 2183 263.62 2246.72 263.62C2331.92 263.62 2358.55 263.62 2456.38 263.62C2515.66 263.62 2541.87 263.62 2581.88 263.62C2621.9 263.62 2648.11 263.62 2706.97 263.62C2827.58 263.62 2813.1 293.795 2926.3 293.795C2989.12 293.795 3056.52 363.453 3089.63 430.348C3122.74 363.453 3175.79 293.795 3239.51 293.795C3303.23 293.795 3356.27 363.453 3389.38 430.348C3422.5 363.453 3500.82 253.327 3613.31 253.327V233.558C3500.82 233.558 3422.5 122.066 3389.38 55.1711C3356.27 122.066 3303.23 191.725 3239.51 191.725C3175.79 191.725 3122.74 122.066 3089.63 55.1711C3056.52 122.066 2989.12 223.27 2926.3 223.27C2813.1 223.27 2827.58 223.27 2706.97 223.27C2647.7 223.27 2621.49 223.27 2581.47 223.27C2541.46 223.27 2515.25 223.27 2456.38 223.27C2358.55 223.27 2331.92 223.27 2246.72 223.27C2183 223.27 2146.51 284.648 2113.4 223.27C2080.29 284.648 2040.59 223.27 1988.81 223.27C1903.92 223.27 1873.47 223.27 1752.09 223.27C1611.56 223.27 1594.1 223.267 1452.17 223.267Z"
      );
    }
    30% {
      clip-path: path(
        "M1452.17 223.267C1338.96 223.267 1331.27 22.12 1210.66 22.12C1142.65 22.12 1118.48 81.29 1118.48 81.29C1118.48 81.29 1094.3 22.12 1026.29 22.12C922.939 22.12 864.418 223.27 804.635 223.27C751.125 223.27 736.661 138.62 662.992 138.62C591.268 138.62 588.429 210.82 542.165 210.82C484.864 210.82 426.82 0.689941 299.922 0.689941C159.395 0.689941 141.939 233.56 0 233.56V253.33C141.939 253.33 159.379 486.19 299.922 486.19C426.835 486.19 484.864 276.06 542.165 276.06C588.429 276.06 591.283 348.27 662.992 348.27C736.677 348.27 751.125 263.62 804.635 263.62C864.418 263.62 922.955 464.76 1026.29 464.76C1099.82 464.76 1118.48 405.6 1118.48 405.6C1118.48 405.6 1137.15 464.76 1210.66 464.76C1331.27 464.76 1338.96 263.618 1452.17 263.618C1594.1 263.618 1611.56 263.62 1752.09 263.62C1873.47 263.62 1903.92 263.62 1988.81 263.62C2040.59 263.62 2036.5 263.62 2113.4 263.62C2176.5 263.62 2183 263.62 2246.72 263.62C2331.92 263.62 2358.55 263.62 2456.38 263.62C2515.66 263.62 2541.87 263.62 2581.88 263.62C2621.9 263.62 2648.11 263.62 2706.97 263.62C2827.58 263.62 2813.1 263.62 2926.3 263.62C2989.12 263.62 3028.5 263.62 3089.63 263.62C3134.5 263.62 3175.79 263.62 3239.51 263.62C3303.23 263.62 3325 263.62 3389.38 263.62C3431.5 263.62 3500.82 253.326 3613.31 253.326V233.558C3500.82 233.558 3441.5 223.27 3389.38 223.27C3337.26 223.27 3303.23 223.27 3239.51 223.27C3175.79 223.27 3146 223.27 3089.63 223.27C3024 223.27 2989.12 223.27 2926.3 223.27C2813.1 223.27 2827.58 223.27 2706.97 223.27C2647.7 223.27 2621.49 223.27 2581.47 223.27C2541.46 223.27 2515.25 223.27 2456.38 223.27C2358.55 223.27 2331.92 223.27 2246.72 223.27C2183 223.27 2178 223.27 2113.4 223.27C2060 223.27 2040.59 223.27 1988.81 223.27C1903.92 223.27 1873.47 223.27 1752.09 223.27C1611.56 223.27 1594.1 223.267 1452.17 223.267Z"
      );
    }

    75% {
      clip-path: path(
        "M1452.17 223.267C1338.96 223.267 1331.27 22.12 1210.66 22.12C1142.65 22.12 1118.48 81.29 1118.48 81.29C1118.48 81.29 1094.3 22.12 1026.29 22.12C922.939 22.12 864.418 223.27 804.635 223.27C751.125 223.27 736.661 138.62 662.992 138.62C591.268 138.62 588.429 210.82 542.165 210.82C484.864 210.82 426.82 0.689941 299.922 0.689941C159.395 0.689941 141.939 233.56 0 233.56V253.33C141.939 253.33 159.379 486.19 299.922 486.19C426.835 486.19 484.864 276.06 542.165 276.06C588.429 276.06 591.283 348.27 662.992 348.27C736.677 348.27 751.125 263.62 804.635 263.62C864.418 263.62 922.955 464.76 1026.29 464.76C1099.82 464.76 1118.48 405.6 1118.48 405.6C1118.48 405.6 1137.15 464.76 1210.66 464.76C1331.27 464.76 1338.96 263.618 1452.17 263.618C1594.1 263.618 1611.56 263.62 1752.09 263.62C1873.47 263.62 1903.92 263.62 1988.81 263.62C2040.59 263.62 2036.5 263.62 2113.4 263.62C2176.5 263.62 2183 263.62 2246.72 263.62C2331.92 263.62 2358.55 263.62 2456.38 263.62C2515.66 263.62 2541.87 263.62 2581.88 263.62C2621.9 263.62 2648.11 263.62 2706.97 263.62C2827.58 263.62 2813.1 263.62 2926.3 263.62C2989.12 263.62 3028.5 263.62 3089.63 263.62C3134.5 263.62 3175.79 263.62 3239.51 263.62C3303.23 263.62 3325 263.62 3389.38 263.62C3431.5 263.62 3500.82 253.326 3613.31 253.326V233.558C3500.82 233.558 3441.5 223.27 3389.38 223.27C3337.26 223.27 3303.23 223.27 3239.51 223.27C3175.79 223.27 3146 223.27 3089.63 223.27C3024 223.27 2989.12 223.27 2926.3 223.27C2813.1 223.27 2827.58 223.27 2706.97 223.27C2647.7 223.27 2621.49 223.27 2581.47 223.27C2541.46 223.27 2515.25 223.27 2456.38 223.27C2358.55 223.27 2331.92 223.27 2246.72 223.27C2183 223.27 2178 223.27 2113.4 223.27C2060 223.27 2040.59 223.27 1988.81 223.27C1903.92 223.27 1873.47 223.27 1752.09 223.27C1611.56 223.27 1594.1 223.267 1452.17 223.267Z"
      );
    }

    100% {
      clip-path: path(
        "M1452.17 223.267C1338.96 223.267 1331.27 22.1238 1210.66 22.1238C1142.65 22.1238 1118.48 81.2877 1118.48 81.2877C1118.48 81.2877 1094.3 22.1238 1026.29 22.1238C922.939 22.1238 864.418 223.267 804.635 223.267C751.125 223.267 736.661 138.617 662.992 138.617C591.268 138.617 588.429 210.821 542.165 210.821C484.864 210.821 426.82 0.689638 299.922 0.689638C159.395 0.689638 141.939 233.558 0 233.558V253.326C141.939 253.326 159.379 486.195 299.922 486.195C426.835 486.195 484.864 276.063 542.165 276.063C588.429 276.063 591.283 348.267 662.992 348.267C736.677 348.267 751.125 263.618 804.635 263.618C864.418 263.618 922.955 464.761 1026.29 464.761C1099.82 464.761 1118.48 405.597 1118.48 405.597C1118.48 405.597 1137.15 464.761 1210.66 464.761C1331.27 464.761 1338.96 263.618 1452.17 263.618C1594.1 263.618 1611.56 485.519 1752.09 485.519C1873.47 485.519 1903.92 352.627 1988.81 352.627C2040.59 352.627 2080.29 396.556 2113.4 457.934C2146.51 396.556 2183 346.902 2246.72 346.902C2331.92 346.902 2358.55 464.085 2456.38 464.085C2515.66 464.085 2541.87 404.831 2581.88 404.831C2621.9 404.831 2648.11 464.085 2706.97 464.085C2827.58 464.085 2813.1 293.795 2926.3 293.795C2989.12 293.795 3056.52 363.453 3089.63 430.348C3122.74 363.453 3175.79 293.795 3239.51 293.795C3303.23 293.795 3356.27 363.453 3389.38 430.348C3422.5 363.453 3500.82 253.326 3613.31 253.326V233.558C3500.82 233.558 3422.5 122.066 3389.38 55.1711C3356.27 122.066 3303.23 191.724 3239.51 191.724C3175.79 191.724 3122.74 122.066 3089.63 55.1711C3056.52 122.066 2989.12 191.724 2926.3 191.724C2813.1 191.724 2827.58 21.4342 2706.97 21.4342C2647.7 21.4342 2621.49 80.6877 2581.47 80.6877C2541.46 80.6877 2515.25 21.4342 2456.38 21.4342C2358.55 21.4342 2331.92 138.617 2246.72 138.617C2183 138.617 2146.51 88.963 2113.4 27.5855C2080.29 88.963 2040.59 132.892 1988.81 132.892C1903.92 132.892 1873.47 0 1752.09 0C1611.56 0 1594.1 223.267 1452.17 223.267Z"
      );
    }
  }

  .animated-texts {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .animated-texts.top {
    padding: 20px;
    @media screen and (min-width: 992px) {
      padding: 40px 60px;
    }
    @media screen and (min-width: 1600px) {
      padding: 60px;
      padding-top: 40px;
      padding-bottom: 30px;
    }
  }
  .animated-texts.bottom {
    padding: 20px;
    @media screen and (min-width: 992px) {
      padding: 20px 60px;
    }
    @media screen and (min-width: 1600px) {
      padding: 25px 60px;
    }
  }

  .ticker {
    width: var(--banner-width);
    height: var(--banner-height);
    position: relative;
    overflow: visible;
  }

  .loop1,
  .loop2 {
    position: absolute;
    width: 1569px;
    left: 0;
    right: 0;
    @media screen and (min-width: 992px) {
      width: 2377px;
    }
    @media screen and (min-width: 1600px) {
      width: 3613px;
    }
  }
  .loop1 {
    transform: translateX(0px);
    animation: loop1 32s linear infinite;
    animation-delay: 2.5s;
  }
  .loop2 {
    transform: translateX(100%);
    animation: loop2 32s linear infinite;
    animation-delay: 2.5s;
  }
</style>
