<!-- @format -->
<script lang="ts">
  import { valueGetters } from "~/components/fragment-sets/basic_component/util";
  import type { StoresKey } from "~/stores";
  import { getStoresContext } from "~/stores";

  export let request_store_key: StoresKey;
  export let fragment: Fragment;
  export let type = "1";

  const stores_context = getStoresContext(request_store_key);

  const { locale } = stores_context;

  const { getImage, getText } = valueGetters(stores_context);

  $: image = $locale && getImage(`image-${type}`, fragment);
  $: video = $locale && getText(`video-${type}`, fragment);
</script>

{#if locale}
  {#if video}
    <div class="kamppi-frame-{type} kamppi-video">
      <div class="kamppi-frame-{type}-{type}">
        <iframe
          class="kamppi-type-{type}"
          src="https://player.vimeo.com/video/{video}?background=1&autoplay=1&loop=1"
          frameborder="0"
          allow="autoplay"
          title="Banner animation type {type}">
        </iframe>
      </div>
    </div>
  {:else if image && "src" in image}
    <div class="kamppi-frame-{type} kamppi-img" style="background-image: url({image.src})" />
  {/if}
{/if}

<style lang="scss">
  .kamppi-frame-1 iframe,
  .kamppi-frame-2 iframe,
  .kamppi-frame-3 iframe,
  .kamppi-frame-4 iframe,
  .kamppi-frame-6 iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .kamppi-video,
  .kamppi-img {
    object-fit: cover;
    position: absolute;
    background-position: center;
    background-size: cover;
    animation: kamppiExpand 0.9s ease-in;
    animation-fill-mode: forwards;
    animation-delay: 0.25s;
    transform: scale(0, 0);
  }

  @keyframes kamppiExpand {
    0% {
      transform: scale(0, 0);
      -webkit-transform: scale(0, 0);
    }
    90% {
      transform: scale(1.05, 1.05);
      -webkit-transform: scale(1.05, 1.05);
    }
    100% {
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
    }
  }

  .kamppi-frame-1,
  .kamppi-frame-4 {
    top: 37px;
    clip-path: url("#type-1-small");
    -webkit-clip-path: url("#type-1-small");

    width: 102px;
    height: 137px;
    @media screen and (min-width: 992px) {
      clip-path: url("#type-1-medium");
      -webkit-clip-path: url("#type-1-medium");
      width: 169px;
      height: 230.38px;
      top: 44.81px;
    }
    @media screen and (min-width: 1600px) {
      clip-path: url("#type-1-large");
      -webkit-clip-path: url("#type-1-large");
      width: 275.32px;
      height: 374.01px;
      top: 56px;
    }
  }

  .kamppi-frame-1-1 {
    width: 100%;
    height: 100%;
    position: relative;
    clip-path: url("#type-1-small");
    -webkit-clip-path: url("#type-1-small");
    
    @media screen and (min-width: 992px) {
      clip-path: url("#type-1-medium");
      -webkit-clip-path: url("#type-1-medium");
    }
    @media screen and (min-width: 1600px) {
      clip-path: url("#type-1-large");
      -webkit-clip-path: url("#type-1-large");
    }
  }
  
  .kamppi-frame-4-4 {
    width: 100%;
    height: 100%;
    position: relative;
    clip-path: url("#type-1-small");
    -webkit-clip-path: url("#type-1-small");
    
    @media screen and (min-width: 992px) {
      clip-path: url("#type-1-medium");
      -webkit-clip-path: url("#type-1-medium");
    }
    @media screen and (min-width: 1600px) {
      clip-path: url("#type-1-large");
      -webkit-clip-path: url("#type-1-large");
    }
  }

  iframe.kamppi-type-1,
  iframe.kamppi-type-4 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .kamppi-frame-1 {
    left: 81px;
    @media screen and (min-width: 992px) {
      left: 113px;
    }
    @media screen and (min-width: 1600px) {
      left: 163px;
    }
  }

  .kamppi-frame-4 {
    left: 715px;
    animation-delay: 2.25s;
    animation-duration: 1s;
    @media screen and (min-width: 992px) {
      left: 1071px;
    }
    @media screen and (min-width: 1600px) {
      left: 1620px;
    }
  }

  .kamppi-frame-2 {
    left: 256px;
    clip-path: circle(50% at 50% 50%);
    top: 73.19px;
    width: 65px;
    height: 65px;
    @media screen and (min-width: 992px) {
      width: 80px;
      height: 80px;
      left: 397px;
      top: 120px;
    }
    @media screen and (min-width: 1600px) {
      width: 130px;
      height: 130px;
      left: 599px;
      top: 178px;
    }
    .kamppi-frame-2-2 {
      width: 100%;
      height: 100%;
      position: relative;
      iframe.kamppi-type-2 {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  .kamppi-frame-3,
  .kamppi-frame-6 {
    position: absolute;
    width: 173px;
    height: 137px;
    top: 37.19px;
    clip-path: url("#type-3-small");
    -webkit-clip-path: url("#type-3-small");
    @media screen and (min-width: 992px) {
      clip-path: url("#type-3-medium");
      -webkit-clip-path: url("#type-3-medium");
      width: 245px;
      height: 193px;
      top: 63.5px;
    }
    @media screen and (min-width: 1600px) {
      clip-path: url("#type-3-large");
      -webkit-clip-path: url("#type-3-large");
      width: 397.75px;
      height: 314.11px;
      top: 86.58px;
    }
  }
  .kamppi-frame-3-3,
  .kamppi-frame-6-6 {
    width: 100%;
    height: 100%;
    position: relative;
  }

  iframe.kamppi-type-3,
  iframe.kamppi-type-6 {
    width: 100%;
    height: 100%;
  }

  .kamppi-frame-6 {
    left: 1036.5px;
    animation-delay: 2.25s;
    animation-duration: 1s;
    @media screen and (min-width: 992px) {
      left: 1575.5px;
    }
    @media screen and (min-width: 1600px) {
      left: 2383px;
    }
  }

  .kamppi-frame-3 {
    left: 400px;
    @media screen and (min-width: 992px) {
      left: 613px;
    }
    @media screen and (min-width: 1600px) {
      left: 920px;
    }
  }
</style>
